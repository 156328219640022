/***************************************************************************************************
 * BROWSER POLYFILLS
 */

// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * import './zone-flags.ts';
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove'];
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 */

// import './zone-flags';
// import 'zone.js/dist/zone';  // Included with Angular CLI.
import 'core-js/es/array';

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

(window as any).global = window;

/***************************************************************************************************
 * WIDGETS IMPORTS
 */

(window as any).GAUGES_NO_AUTO_INIT = true;
